// @flow
import React from 'react';
import Layout from '../../components/Layout/Layout';
import ApplySection from '../../components/Merchant/ApplySection';
import Hero from '../../components/Merchant/shared/Hero';
import Navigation from '../../components/Merchant/Partner/Navigation';
import Bg from '../../images/case_study.svg';
import BgSp from '../../images/partner_sp.svg';
import { partnerStyles } from '../../styles/pages/merchant.styles';

const PartnerPage = ({ location }: { location: Object }) => {
  const classes = partnerStyles({});

  return (
    <Layout isMerchant>
      <Hero
        title="ペイディパートナー"
        subTitle="オフィシャルパートナー"
        bg={Bg}
        bgSp={BgSp}
        classNames={classes.partnerHeroWrapper}
      />
      <Navigation location={location} />
      <ApplySection />
    </Layout>
  );
};

// $FlowFixMe
export default PartnerPage;
