/* eslint-disable import/prefer-default-export */
export const blocks = [
  {
    id: 'premium-partner',
    title: 'プレミアムパートナー',
  },
  {
    id: 'meister',
    title: 'ペイディマイスター',
  },
];
