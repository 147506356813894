// @flow
import React from 'react';
import loadable from '@loadable/component';
import { blocks } from './const';
import NavigationBlock from '../shared/NavigationBlock';
import { smoothScrollToElement } from '../../../utils';

const PremiumParter = loadable(() =>
  import(/* webpackPreload: true */ './PremiumPartner')
);
const Meister = loadable(() => import(/* webpackPreload: true */ './Meister'));

const Navigation = ({ location }: { location: Object }) => {
  if (location.hash) {
    setTimeout(
      () =>
        smoothScrollToElement(location.hash, {
          duration: 1000,
          delay: 100,
          smooth: true,
          offset: -187,
        }),
      500
    );
  }

  return (
    <NavigationBlock blocks={blocks}>
      <PremiumParter />
      <Meister />
    </NavigationBlock>
  );
};

export default Navigation;
